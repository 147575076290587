<template>
  <default-layout>
    <v-container id="registration-management" fluid tag="section">
      <page-loading :show="isLoading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <inquiry-list
        :items="inquiries"
        :meta="meta"
        :permissions="permissions"
        :filters="filters"
        @changePage="changePage"
        @changeFilter="changeFilter"
      ></inquiry-list>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const InquiryList = () => import('@/components/project-inquiries/list');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    InquiryList,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.projectInquiry.permissions,
      inquiries: (state) => state.projectInquiry.inquiries,
      meta: (state) => state.projectInquiry.meta,
      filters: (state) => state.projectInquiry.filterStatuses,
    }),
  },
  async mounted() {
    try {
      this.isLoading = true;
      let query = this.$route.query;
      this.$store.commit('projectInquiry/RESET_DEFAULT');
      const response = await this.$store.dispatch('projectInquiry/getInitData', query);
      console.log('CHECK PROJECT INQUIRIES INIT DATA: ', response);
    } catch (e) {
      this.showAlert = true;
      this.messageAlert = e;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async loadData(query) {
      try {
        this.isLoading = true;
        const response = await this.$store.dispatch('projectInquiry/getInitData', query);
        console.log('CHANGE PAGE PROJECT INQUIRIES DATA: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      } finally {
        this.isLoading = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async changePage(page) {
      let query = this.$route.query;
      query.page = page;
      await this.loadData(query);
    },
    async changeFilter(val) {
      let query = this.$route.query;
      query.status_filter = val;
      await this.loadData(query);
    },
  },
};
</script>
